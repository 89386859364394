import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { PaginationComponent } from './pagination.component';

@NgModule({
    declarations: [
        PaginationComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [
        PaginationComponent
    ]
})

export class PaginationModule {
}
