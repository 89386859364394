import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {DynamicFeedModule} from './dynamic-feed/dynamic-feed.module';
import {JsFormModule} from './js-form/js-form.module';
import {SharedModule} from '../../shared/shared.module';
import {ToolsComponent} from './tools.component';
import {ToolsRoutingModule} from './tools-routing.module';
import {ToolsService} from './services/tools.service';

@NgModule({
  declarations: [
    ToolsComponent
  ],
  imports: [
    DynamicFeedModule,
    ToolsRoutingModule,
    //NgbModule,
    JsFormModule,
    SharedModule
  ],
  providers: [ToolsService]
})

export class ToolsModule {
}
