import { Component, OnInit } from '@angular/core';
import {ToolsService} from './services/tools.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html'
})
export class ToolsComponent  implements OnInit {


  constructor(public toolsService: ToolsService) { }

  ngOnInit() {
  }

}
