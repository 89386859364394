import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ToolsComponent} from './tools.component';

const routes: Routes = [
  {
    path: '',
    component: ToolsComponent,
    children: [
      {
        path: 'dynamic-feed',
        loadChildren: './dynamic-feed/dynamic-feed.module#DynamicFeedModule'
      },
      {
        path: 'js-form',
        loadChildren: './js-form/js-form.module#JsFormModule'
      },
      {
        path: 'chatbot',
        loadChildren: './chatbot/chatbot.module#ChatbotModule'
      },
      {
        path: 'smart-link',
        loadChildren: './smart-link/smart-link.module#SmartLinkModule'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class ToolsRoutingModule {
}
