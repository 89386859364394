import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

export interface PaginationParams {
    count: number,
    limit: number,
    skip: number,
};

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
    count: number;
    limit: number;
    paginator: number[];
    skip: number;

    @Input() set options (options: {count: number, limit: number, skip: number}) {
        this.count = options.count;
        this.limit = options.limit;
        this.paginator = [];
        this.skip = options.skip;

        if (this.count < 6 * this.limit || this.skip < this.limit * 3) {
            const iterations = this.count < 6 * this.limit ? Math.ceil((this.count - this.limit % this.limit) / this.limit) : 5;

            for (let i = 0; i < iterations; i++) {
                this.paginator.push(i * this.limit);
            }
        } else if (this.skip < this.count - this.limit * 2) {
            for (let i = -2; i < 3; i++) {
                this.paginator.push((this.skip) + (i * this.limit));
            }
        } else {
            const remainder = Math.ceil((this.count - this.skip) / this.limit);

            for (let i = -5 + remainder; i < remainder; i++) {
                this.paginator.push((this.skip) + (i * this.limit));
            }
        }
    }

    @Output() OnChangePage = new EventEmitter<number>();

    changePage(skip: number) {
        if (skip !== this.skip) {
            this.OnChangePage.emit(skip);
        }
    }
}
